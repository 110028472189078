import React, { Component, Fragment } from "react";
import { Container } from "react-bootstrap";
import FooterDesktop from "../components/common/FooterDesktop";
import NavMenuDesktop from "../components/common/NavMenuDesktop";
import About from "../components/others/About";

export default class PolicyPage extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }
  render() {
    return (
      <Fragment>
        <NavMenuDesktop />
        <Container
          className="TopSection animated slideInDown"
          style={{ paddingTop: "100px", paddingBottom: "50px" }}
        >
          <About />
        </Container>
        <FooterDesktop />
      </Fragment>
    );
  }
}
