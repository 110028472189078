import React, { Component } from "react";
import { Route, Routes } from "react-router";
import ContactPage from "../pages/ContactPage";
import HomePage from "../pages/HomePage";
import PolicyPage from "../pages/PolicyPage";
import PurchasePage from "../pages/PurchasePage";
import RefundPage from "../pages/RefundPage";
import SigninPage from "../pages/SigninPage";
import AboutPage from "../pages/AboutPage";

export class AppRoute extends Component {
  render() {
    return (
      <>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/signin" element={<SigninPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/policy" element={<PolicyPage />} />
          <Route path="/refund" element={<RefundPage />} />
          <Route path="/purchase" element={<PurchasePage />} />
          <Route path="/about" element={<AboutPage />} />
        </Routes>
      </>
    );
  }
}

export default AppRoute;
