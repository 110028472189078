//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import FooterDesktop from "../components/common/FooterDesktop";
import NavMenuDesktop from "../components/common/NavMenuDesktop";
import Categories from "../components/home/Categories";
import Collections from "../components/home/Collections";
//import { faCoffee } from "@fortawesome/free-solid-svg-icons";
import FeaturedProduct from "../components/home/FeaturedProduct";
import HomeTop from "../components/home/HomeTop";
import NewArrival from "../components/home/NewArrival";
import axios from "axios";
import ApiUrl from "../api/ApiUrl";

export class HomePage extends Component {
  componentDidMount() {
    window.scroll(0, 0);
    this.GetVisitorDetails();
  }

  //Get visitor details from the visitors
  GetVisitorDetails = () => {
    axios.get(ApiUrl.VisitorDetails).then().catch();
  };

  render() {
    return (
      <>
        <NavMenuDesktop />
        <HomeTop />
        <FeaturedProduct />
        <Categories />
        <Collections />
        <NewArrival />
        <FooterDesktop />
      </>
    );
  }
}

export default HomePage;
