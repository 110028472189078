import axios from "axios";
import React, { Component, Fragment } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import ApiUrl from "../../api/ApiUrl";
import validation from "../../validation/Validation";

export default class Contact extends Component {
  constructor() {
    super();

    this.state = {
      name: "",
      mobile: "",
      message: "",
      loading: "Send",
    };
  }

  inputChangeHandler = (event) => {
    var inputName = event.target.name;
    var inputValue = event.target.value;

    this.setState({
      [inputName]: inputValue,
    });
  };

  onFormSubmit = (event) => {
    event.preventDefault();

    let name = this.state.name;
    let mobile = this.state.mobile;
    let message = this.state.message;

    // let sendBtn=document.getElementById('sendBtn');
    // let contactForm=document.getElementById('contactForm');

    if (name.length === 0) {
      alert(" enter a name");
    } else if (mobile.length === 0) {
      alert(" enter a mobile");
    } else if (!validation.NameRegx.test(name)) {
      alert("valid  enter a name");
    } else if (!validation.MobileRegx.test(mobile)) {
      alert("Please enter a mobile");
    } else if (message.length === 0) {
      alert("Please enter a name");
    } else {
      //Set animation for send data
      this.setState({
        loading: "Sending..",
      });
      let formData = new FormData();

      formData.append("name", name);
      formData.append("mobile", mobile);
      formData.append("message", message);

      axios
        .post(ApiUrl.SendContactDetails, formData)
        .then((response) => {
          if (response.status === 200 && response.data === 1) {
            alert("success");
            //After sending data successfully, we reset the form
            this.setState({
              loading: "Send",
              name: "",
              mobile: "",
              message: "",
            });
          } else {
            alert("error");
          }
        })
        .catch((error) => {
          alert(error.message);
        });
    }
  };
  render() {
    return (
      <Fragment>
        <Container className="TopSection animated slideInDown">
          <Row className="d-flex p-1 justify-content-center">
            <Col
              className="shadow-sm bg-white mt-2"
              md={12}
              lg={12}
              sm={12}
              xs={12}
            >
              <Row className="text-center ">
                <Col
                  className="d-flex justify-content-center"
                  md={6}
                  lg={6}
                  sm={12}
                  xs={12}
                >
                  <Form
                    id="contactForm"
                    onSubmit={this.onFormSubmit}
                    className="onboardForm"
                  >
                    <h4 className="section-title">CONTACT WITH US</h4>
                    <h6 className="section-sub-title">
                      Enter Your name, mobile and address, And click send
                    </h6>
                    <input
                      onChange={this.inputChangeHandler}
                      className="form-control m-2"
                      name="name"
                      type="text"
                      value={this.state.name}
                      placeholder="Enter your name"
                    />
                    <input
                      onChange={this.inputChangeHandler}
                      className="form-control m-2"
                      name="mobile"
                      type="text"
                      value={this.state.mobile}
                      placeholder="Enter your mobile number"
                    />
                    <input
                      onChange={this.inputChangeHandler}
                      className="form-control m-2"
                      name="message"
                      type="text"
                      value={this.state.message}
                      placeholder="Enter your address"
                    />
                    <Button
                      id="sendBtn"
                      type="submit"
                      className="btn btn-block m-2 site-btn w-100"
                    >
                      {this.state.loading}
                    </Button>
                  </Form>
                </Col>
                <Col className="p-0 Desktop m-0" md={6} lg={6} sm={6} xs={6}>
                  <iframe
                    className="GoogleMap"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3651.5512686644793!2d90.34091951479688!3d23.763376144197853!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c08273623b31%3A0xe53ceed0d39fadfd!2sDhaka%20Uddan%20Housing%2C%20Dhaka!5e0!3m2!1sen!2sbd!4v1659248524572!5m2!1sen!2sbd"
                    width="600"
                    height="450"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                    title="Map"
                  ></iframe>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}
