import axios from "axios";
import React, { Component, Fragment } from "react";
import { Container, Row, Col, Breadcrumb, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import ApiUrl from "../../api/ApiUrl";
import parse from "html-react-parser";
import { toast, ToastContainer } from "react-toastify";

class Policy extends Component {
  constructor() {
    super();
    this.state = {
      policy: "",
      loaderDiv: "",
      mainDiv: "d-none",
    };
  }
  componentDidMount() {
    let PolicyInfo = sessionStorage.getItem("policyInfo");

    if (PolicyInfo === null) {
      axios
        .get(ApiUrl.GetSiteInfo)
        .then((response) => {
          let StatusCode = response.status;
          if (StatusCode === 200) {
            let policyData = response.data[0]["policy"];
            sessionStorage.setItem("policyInfo", policyData);
            this.setState({
              policy: policyData,
              loaderDiv: "d-none",
              mainDiv: "",
            });
          } else {
            toast.info("Something went wrong!", { position: "top-center" });
          }
        })
        .catch((error) => {
          toast.info("Something went wrong!", { position: "top-center" });
        });
    } else {
      this.setState({ policy: PolicyInfo, loaderDiv: "d-none", mainDiv: "" });
    }
  }
  render() {
    return (
      <Fragment>
        <ToastContainer />
        <Container className="TopSection">
          <Breadcrumb className="shadow-sm mt-2 bg-white">
            <Breadcrumb.Item>
              {" "}
              <Link to="/">Home</Link>{" "}
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {" "}
              <Link to="/about">About</Link>{" "}
            </Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col className="mt-1" md={12} lg={12} sm={12} xs={12}>
              <Card className={this.state.loaderDiv}>
                <Card.Body>
                  <div class="ph-item">
                    <div class="ph-col-12">
                      <div class="ph-row">
                        <div class="ph-col-12"></div>
                        <div class="ph-col-12"></div>
                        <div class="ph-col-12"></div>
                      </div>
                    </div>
                    <div class="ph-col-12">
                      <div class="ph-row">
                        <div class="ph-col-12"></div>
                        <div class="ph-col-12"></div>
                        <div class="ph-col-12"></div>
                      </div>
                    </div>
                    <div class="ph-col-12">
                      <div class="ph-row">
                        <div class="ph-col-12"></div>
                        <div class="ph-col-12"></div>
                        <div class="ph-col-12"></div>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>

              <Card className={this.state.mainDiv}>
                <Card.Body>
                  {/* <DescriptionPlaceholder isLoading={this.state.loaderDiv}/> */}
                  <div>
                    <div className="animated zoomIn">
                      {parse(this.state.policy)}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}
export default Policy;
